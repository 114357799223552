export const departmentNames = [
  "African American Studies",
  "African Studies",
  "American Studies",
  "Anesthesiology",
  "Anthropology",
  "Applied Mathematics",
  "Applied Physics",
  "Archaeological Studies",
  "Architecture",
  "Art",
  "Astronomy",
  "Biological and Biomedical Sciences",
  "Biomedical Engineering",
  "Biostatistics",
  "Cell Biology",
  "Cellular and Molecular Physiology",
  "Chemical and Environmental Engineering",
  "Chemistry",
  "Child Study Center",
  "Chronic Disease Epidemiology",
  "Classics",
  "Cognitive Science",
  "Comparative Literature",
  "Comparative Medicine",
  "Computational Biology and Bioinformatics",
  "Computer Science",
  "Dermatology",
  "Early Modern Studies",
  "Earth and Planetary Sciences",
  "East Asian Languages and Literatures",
  "East Asian Studies",
  "Ecology and Evolutionary Biology",
  "Economics",
  "Electrical Engineering",
  "Emergency Medicine",
  "Engineering and Applied Science",
  "English",
  "Environmental Health Sciences",
  "Environmental Studies",
  "Epidemiology of Microbial Diseases",
  "Ethics, Politics and Economics",
  "Ethnicity, Race and Migration",
  "European and Russian Studies",
  "Experimental Pathology",
  "Film and Media Studies",
  "Forestry and Environmental Studies",
  "French",
  "Genetics",
  "Geology and Geophysics",
  "German",
  "Global Affairs",
  "Health Care Management",
  "Health Policy and Management",
  "Hellenic Studies",
  "History",
  "History of Art",
  "History of Medicine",
  "History of Science and Medicine",
  "Humanities",
  "Immunobiology",
  "Internal Medicine",
  "International and Development Economics",
  "Investigative Medicine",
  "Italian",
  "Judaic Studies",
  "Laboratory Medicine",
  "Latin American Studies",
  "Law",
  "Linguistics",
  "MCDB",
  "Management",
  "Mathematics",
  "Mechanical Engineering and Materials Science",
  "Medicine",
  "Medieval Studies",
  "Microbial Pathogenesis",
  "Microbiology",
  "Modern Middle East Studies",
  "Molecular Biophysics and Biochemistry",
  "Molecular, Cellular and Developmental Biology",
  "Music",
  "Near Eastern Langauges and Civilizations",
  "Neurology",
  "Neuroscience",
  "Neurosurgery",
  "Nursing",
  "Obstetrics, Gynecology and Reproductive Sciences",
  "Ophthalmology and Visual Science",
  "Orthopaedics and Rehabilitation",
  "Pathology",
  "Pediatrics",
  "Pharmacology",
  "Philosophy",
  "Physics",
  "Political Science",
  "Psychiatry",
  "Psychology",
  "Public Health",
  "Radiology and Biomedical Imaging",
  "Religious Studies",
  "Slavic Languages and Literatures",
  "Sociology",
  "South Asian Studies",
  "Spanish and Portuguese",
  "Statistics",
  "Surgery",
  "Theater Studies",
  "Therapeutic Radiology",
  "Urology",
  "Women’s, Gender, and Sexuality Studies",
];

export const departmentCategories = {
  // Group 0: Humanities
  "African American Studies": 0,
  "American Studies": 0,
  "Archaeological Studies": 0,
  "Architecture": 0,
  "Art": 0,
  "Classics": 0,
  "Comparative Literature": 0,
  "Early Modern Studies": 0,
  "English": 0,
  "Ethics, Politics and Economics": 0,
  "Film and Media Studies": 0,
  "History": 0,
  "History of Art": 0,
  "History of Medicine": 0,
  "History of Science and Medicine": 0,
  "Humanities": 0,
  "Judaic Studies": 0,
  "Medieval Studies": 0,
  "Music": 0,
  "Philosophy": 0,
  "Religious Studies": 0,
  "Theater Studies": 0,

  // Group 1: Social Sciences
  "African Studies": 1,
  "Anthropology": 1,
  "Cognitive Science": 1,
  "Economics": 1,
  "Ethnicity, Race and Migration": 1,
  "European and Russian Studies": 1,
  "Global Affairs": 1,
  "International and Development Economics": 1,
  "Political Science": 1,
  "Psychology": 1,
  "Sociology": 1,
  "South Asian Studies": 1,
  "Women's, Gender, and Sexuality Studies": 1,

  // Group 2: Physical Sciences & Mathematics
  "Applied Mathematics": 2,
  "Astronomy": 2,
  "Chemistry": 2,
  "Earth and Planetary Sciences": 2,
  "Geology and Geophysics": 2,
  "Mathematics": 2,
  "Physics": 2,
  "Statistics": 2,

  // Group 3: Life Sciences
  "Biological and Biomedical Sciences": 3,
  "Cell Biology": 3,
  "Cellular and Molecular Physiology": 3,
  "Computational Biology and Bioinformatics": 3,
  "Ecology and Evolutionary Biology": 3,
  "Environmental Studies": 3,
  "Forestry and Environmental Studies": 3,
  "Genetics": 3,
  "Immunobiology": 3,
  "MCDB": 3,
  "Microbiology": 3,
  "Molecular Biophysics and Biochemistry": 3,
  "Molecular, Cellular and Developmental Biology": 3,
  "Neuroscience": 3,

  // Group 4: Engineering & Computer Science
  "Applied Physics": 4,
  "Biomedical Engineering": 4,
  "Chemical and Environmental Engineering": 4,
  "Computer Science": 4,
  "Electrical Engineering": 4,
  "Engineering and Applied Science": 4,
  "Mechanical Engineering and Materials Science": 4,

  // Group 5: Medical & Health Sciences
  "Anesthesiology": 5,
  "Biostatistics": 5,
  "Child Study Center": 5,
  "Chronic Disease Epidemiology": 5,
  "Comparative Medicine": 5,
  "Dermatology": 5,
  "Emergency Medicine": 5,
  "Environmental Health Sciences": 5,
  "Epidemiology of Microbial Diseases": 5,
  "Experimental Pathology": 5,
  "Health Care Management": 5,
  "Health Policy and Management": 5,
  "Internal Medicine": 5,
  "Investigative Medicine": 5,
  "Laboratory Medicine": 5,
  "Medicine": 5,
  "Microbial Pathogenesis": 5,
  "Neurology": 5,
  "Neurosurgery": 5,
  "Nursing": 5,
  "Obstetrics, Gynecology and Reproductive Sciences": 5,
  "Ophthalmology and Visual Science": 5,
  "Orthopaedics and Rehabilitation": 5,
  "Pathology": 5,
  "Pediatrics": 5,
  "Pharmacology": 5,
  "Psychiatry": 5,
  "Public Health": 5,
  "Radiology and Biomedical Imaging": 5,
  "Surgery": 5,
  "Therapeutic Radiology": 5,
  "Urology": 5,

  // Group 6: Languages & Cultural Studies
  "East Asian Languages and Literatures": 6,
  "East Asian Studies": 6,
  "French": 6,
  "German": 6,
  "Italian": 6,
  "Linguistics": 6,
  "Near Eastern Langauges and Civilizations": 6,
  "Slavic Languages and Literatures": 6,
  "Spanish and Portuguese": 6,
  "Latin American Studies": 6,
  "Modern Middle East Studies": 6,
  "Hellenic Studies": 6,

  // Group 7: Professional & Applied Fields
  "Law": 7,
  "Management": 7
};
